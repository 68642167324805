<template>
  <div>
    <component :is="`h${headingLevel}`" class="subtitle">Chat</component>
    <component :is="`h${headingLevel + 1}`" class="subsubtitle"
      >Mit einem {{ $brand.name }} Berater</component
    >
    <div v-if="chatAvailable">
      <p class="description">
        Im Chat stehen wir Ihnen Mo.-Fr. von 08-18 Uhr und Sa. von 10-18 Uhr zu
        allen Themen kostenfrei zur Verfügung.
      </p>
      <ButtonComponent
        data-cy="open-chat-button"
        :clickhandler="() => $bus.emit('open-chat')"
        @click.stop
        >Chat starten</ButtonComponent
      >
    </div>
    <div v-else>
      <p class="description">
        Leider steht zurzeit kein {{ $brand.name }} Berater für den Chat zur
        Verfügung.
      </p>
      <p class="description">
        Bitte wenden Sie sich an die {{ $brand.name }} Kundenbetreuung.
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'

export default {
  name: 'KontaktChat',
  components: {
    ButtonComponent
  },
  props: {
    headingLevel: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      checkChatInterval: undefined
    }
  },
  computed: {
    ...mapState({
      chatAvailable: state => state.chat.chatAvailable
    })
  },
  created() {
    this.$store.dispatch('chat/updateChatStatus')
    this.checkChatInterval = setInterval(
      () => this.$store.dispatch('chat/updateChatStatus'),
      10000
    )
  },
  beforeUnmount() {
    clearInterval(this.checkChatInterval)
  }
}
</script>

<style lang="scss" scoped>
.subtitle {
  margin: 0.5rem 0;
}
</style>
